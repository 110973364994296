import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`The choice component can be used when a user has to choose from a list of
grouped options. They are a visual alternative to radio's and checkboxes and
allow a selection of single or multiple options. Besides being a visual
alternative, Choices are primarily used to create a list of related options
that can be expanded. When selected, they can contain extra content or
actions.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=656%3A0" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "single-selection",
      "style": {
        "position": "relative"
      }
    }}>{`Single selection`}</h3>
    <p>{`When a user has to pick a single option, use the radio type choice.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=546%3A0" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "mulitple-selections",
      "style": {
        "position": "relative"
      }
    }}>{`Mulitple selections`}</h3>
    <p>{`When a user can choose from a set of multiple options, use the checkbox type
choice.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=548%3A0" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "collapsible-choices",
      "style": {
        "position": "relative"
      }
    }}>{`Collapsible choices`}</h3>
    <p>{`Choices have the option to add extra body content (text, actions, form
elements,...) that can be revealed after selecting an option.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=548%3A136" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <p>{`The choice component should:`}</p>
    <ul>
      <li parentName="ul">{`Take up 100% available width of it's parent container.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Info`}</inlineCode>{` and `}<inlineCode parentName="li">{`Caption`}</inlineCode>{` are optionable, the `}<inlineCode parentName="li">{`Title`}</inlineCode>{` is always required.`}</li>
    </ul>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`When a user has to make selection from more than 4 options, consider using the
`}<a parentName="li" {...{
          "href": "/components/select/code"
        }}>{`Select`}</a>{` component.`}</li>
      <li parentName="ul">{`To create a simple list of options where the user needs to make a `}<strong parentName="li">{`single`}</strong>{`
selection, use the `}<a parentName="li" {...{
          "href": "/components/radio/code"
        }}>{`Radio`}</a>{` component.`}</li>
      <li parentName="ul">{`To create a simple list of option where the user can make `}<strong parentName="li">{`multiple`}</strong>{`
selections, use the `}<a parentName="li" {...{
          "href": "/components/checkbox/code"
        }}>{`Checkbox`}</a>{` component.`}</li>
      <li parentName="ul">{`To create a list of items that can be expanded and collapsed, use the
`}<a parentName="li" {...{
          "href": "/components/accordion/code"
        }}>{`Accordion`}</a>{` component.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      